(function () {
  const task = getTaskID();
  if (task) {
    console.log(`Jira taskId: ${task}`);
    console.log(`URL: https://pixrig.atlassian.net/browse/${task}`);
    console.log('Hash commit: ', process.env.BITBUCKET_COMMIT);
  }

  function getTaskID() {
    const commitMessage = process.env.COMMIT_MESSAGE;
    if (commitMessage) {
      const taskID = commitMessage.match(/EN-\d+/);
      if (taskID) {
        return taskID[0];
      }
    }
    return null;
  }
})();
